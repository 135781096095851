import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import PropTypes from 'prop-types';


class ResauxSociauxPage extends Component {

	componentDidMount() {
	}




	render() {

		const content = this.props.data.allContentfulAccueil.edges[0].node;

		return(
		<Layout>
		
		<SEO title="réseaux sociaux" description="suivez mon actualité" />
	
        <section className="module-md" >
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="m-title c-align">
                        <h1>Réseaux sociaux</h1>
                        <h6>Suivez mon actualité</h6>
                    </div>
                </div>
            </div>
        </div>
        </section>

        <section class="module" id="services">
				<div class="container">
					<div class="row">
						<div class="col-md-4 ">
							<div class="icon-box">
                            <div class="icon-box-icon"><i class="fa fa-facebook"></i></div>
								<div class="icon-box-title">
									<h5><a href="https://www.facebook.com/herve.cohonner/" target="_blank">@herve.cohonner</a></h5>
								</div>
								<div class="icon-box-content">
									<p>Ma page Facebook principale avec toutes mes actualités</p>
								</div>
							</div>
						</div>
                    </div>

                    <div class="row">
						<div class="col-md-4">
							<div class="icon-box">
								<div class="icon-box-icon"><i class="fa fa-instagram"></i></div>
								<div class="icon-box-title">
									<h5><a href="https://www.instagram.com/hervecohonner/" target="_blank">@hervecohonner</a></h5>
								</div>
								<div class="icon-box-content">
									<p>Mon compte Instagram principal avec toutes mes actualités</p>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="icon-box">
                            <div class="icon-box-icon"><i class="fa fa-instagram"></i></div>
								<div class="icon-box-title">
                                <h5><a href="https://www.instagram.com/instabreizh56/" target="_blank">@instabreizh56</a></h5>
								</div>
								<div class="icon-box-content">
									<p>Des tranches de ma vie de tous les jours. Juste pour le plaisir.</p>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="icon-box">
							    <div class="icon-box-icon"><i class="fa fa-instagram"></i></div>
								<div class="icon-box-title">
                                <h5><a href="https://www.instagram.com/paysagedebretagne/" target="_blank">@paysagedebretagne</a></h5>
								</div>
								<div class="icon-box-content">
									<p>La Bretagne sous toutes ces facettes</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
        
        


		
	  </Layout>)
	}
}




ResauxSociauxPage.propTypes = {
	data: PropTypes.object.isRequired,
	
  }

export default ResauxSociauxPage

export const staticQuery = graphql`
  query {
    allContentfulAccueil (limit:1) {
      edges {
        node {
          id

		  imageThankYou {
			title
			fluid(maxWidth: 1920, resizingBehavior: FILL) {
				sizes
				src
				srcSet
				aspectRatio
			}
		  }


		  metaTitle
		  metaDescription
		  metaKeywords
        }
      }
    }
  }
`